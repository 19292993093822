var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v(" Registration - step 3 of 3 ")
      ]),
      _c(
        "gov-back-link",
        { attrs: { to: { name: "register-existing-step2" } } },
        [_vm._v("\n    Back\n  ")]
      ),
      _c("user-account", { attrs: { form: _vm.form, errors: _vm.errors } }),
      _c(
        "gov-button",
        {
          attrs: { start: "", disabled: _vm.fieldsEmpty },
          on: {
            click: function($event) {
              return _vm.$emit("completed")
            }
          }
        },
        [_vm._v("\n    Next\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }