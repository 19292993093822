var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v(" Create an account ")
      ]),
      _c("gov-body", [
        _vm._v(" Please enter your details below to create an account. ")
      ]),
      _c(
        "gov-hint",
        [
          _vm._v("\n    By signing up you agree to our\n    "),
          _c(
            "gov-link",
            { attrs: { target: "_blank", href: _vm.termsAndConditionsUrl } },
            [_vm._v("T&Cs")]
          ),
          _vm._v("\n    and\n    "),
          _c(
            "gov-link",
            { attrs: { target: "_blank", href: _vm.privacyPolicyUrl } },
            [_vm._v("Privacy Policy")]
          )
        ],
        1
      ),
      _c("ck-text-input", {
        attrs: {
          id: "first_name",
          label: "First name",
          type: "text",
          error: _vm.errors.get("user.first_name")
        },
        model: {
          value: _vm.form.user.first_name,
          callback: function($$v) {
            _vm.$set(_vm.form.user, "first_name", $$v)
          },
          expression: "form.user.first_name"
        }
      }),
      _c("ck-text-input", {
        attrs: {
          id: "last_name",
          label: "Last name",
          type: "text",
          error: _vm.errors.get("user.last_name")
        },
        model: {
          value: _vm.form.user.last_name,
          callback: function($$v) {
            _vm.$set(_vm.form.user, "last_name", $$v)
          },
          expression: "form.user.last_name"
        }
      }),
      _c("ck-text-input", {
        attrs: {
          id: "email",
          label: "Email",
          type: "email",
          error: _vm.errors.get("user.email")
        },
        model: {
          value: _vm.form.user.email,
          callback: function($$v) {
            _vm.$set(_vm.form.user, "email", $$v)
          },
          expression: "form.user.email"
        }
      }),
      _c("ck-text-input", {
        attrs: {
          id: "phone",
          label: "Mobile Phone",
          type: "tel",
          error: _vm.errors.get("user.phone")
        },
        model: {
          value: _vm.form.user.phone,
          callback: function($$v) {
            _vm.$set(_vm.form.user, "phone", $$v)
          },
          expression: "form.user.phone"
        }
      }),
      _c("ck-password", {
        attrs: {
          id: "password",
          label: "Password",
          error: _vm.errors.get("user.password")
        },
        model: {
          value: _vm.form.user.password,
          callback: function($$v) {
            _vm.$set(_vm.form.user, "password", $$v)
          },
          expression: "form.user.password"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }