var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass: "govuk-header",
      attrs: { role: "banner", "data-module": "header" }
    },
    [
      _c(
        "div",
        { staticClass: "govuk-header__container govuk-width-container" },
        [
          _c(
            "div",
            { staticClass: "govuk-header__logo" },
            [
              _c(
                "router-link",
                {
                  staticClass:
                    "govuk-header__link govuk-header__link--homepage",
                  attrs: { to: { name: "dashboard" } }
                },
                [
                  _c("span", { staticClass: "govuk-header__logotype" }, [
                    _c("img", {
                      staticClass: "govuk-header__logotype-crown",
                      attrs: { src: _vm.logo }
                    })
                  ])
                ]
              )
            ],
            1
          ),
          _c("div", { staticClass: "govuk-header__content" }, [
            _c(
              "div",
              { staticClass: "ck-header__main" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "govuk-header__link govuk-header__link--service-name",
                    attrs: { to: { name: "dashboard" } }
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.serviceName) + "\n        "
                    )
                  ]
                ),
                _vm.auth.isLoggedIn && _vm.auth.user
                  ? _c(
                      "div",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "govuk-header__link",
                            attrs: {
                              to: {
                                name: "users-show",
                                params: { user: _vm.auth.user.id }
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.auth.user.first_name) +
                                " " +
                                _vm._s(_vm.auth.user.last_name)
                            )
                          ]
                        ),
                        _vm._v("\n           \n          "),
                        _c(
                          "router-link",
                          {
                            staticClass: "govuk-header__link",
                            attrs: { to: { name: "logout" } }
                          },
                          [_vm._v("Logout")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass: "govuk-header__menu-button js-header-toggle",
                attrs: {
                  role: "button",
                  "aria-controls": "navigation",
                  "aria-label": "Show or hide Top Level Navigation"
                },
                on: { click: _vm.onToggleMenu }
              },
              [_vm._v("\n        Menu\n      ")]
            ),
            _c("nav", [
              _c(
                "ul",
                {
                  staticClass: "govuk-header__navigation",
                  class: { "govuk-header__navigation--open": _vm.navExpanded },
                  attrs: {
                    id: "navigation",
                    "aria-label": "Top Level Navigation"
                  }
                },
                _vm._l(_vm.navigation, function(item, key) {
                  return item.to && !item.hide
                    ? _c(
                        "router-link",
                        {
                          key: key,
                          staticClass: "govuk-header__navigation-item",
                          attrs: {
                            tag: "li",
                            "active-class":
                              "govuk-header__navigation-item--active",
                            to: item.to
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "govuk-header__link",
                              attrs: { href: item.to }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.text) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    : item.href
                    ? _c(
                        "li",
                        { staticClass: "govuk-header__navigation-item" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "govuk-header__link",
                              attrs: { href: item.href }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.text) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                }),
                1
              )
            ])
          ])
        ]
      ),
      _c("ck-environment-warning")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }